import React, { useState } from 'react';
import useTimeout from '@deadline/common/hooks/useTimeout';
import TextField from '../TextField/TextField';
import Button from '../Button/';
import Loader from '../Loader';
import { Form } from './ConatctForm.style';

export default function ContactForm() {
  const [emailValue, setEmailValue] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pending, setPending] = useState(false);
  const { start } = useTimeout(() => {
    if (error) setError(false);
    if (success) setSuccess(false);
  }, 3500);
  const handleSubmit = async e => {
    e.preventDefault();
    setPending(true);
    try {
      const response = await fetch('/add-contact', {
          headers: {
              'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({email: emailValue, language: localStorage.getItem('lang')}),
        }
      );
      const data = await response.json();
      setPending(false);
      if (data.job_id) {
        setSuccess(true);
        setError(false);
        start();
        setEmailValue('');
      } else {
        setError(true);
        setSuccess(false);
        start();
      }

      // setTimeout(function() {
      //   setPending(false);
      //   setSuccess(true);
      //   setError(false);
      //   start();
      //   setEmailValue('');
      // }, 2000);

    } catch (err) {
      setPending(false);
      setSuccess(false);
      setError(true);
      start();
    }
  };

  const handleChange = event => {
    setEmailValue(event.target.value);
  };
  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        id="user_email"
        placeholder="placeholder"
        type="email"
        required="required"
        value={emailValue}
        error={error}
        errorMessage="errorMessage"
        successMessage="successMsg"
        success={success}
        onChange={handleChange}
      />
      <Button
        type="submit"
        title="buttonText"
        isLoading={pending}
        loader={<Loader loaderColor="white" />}
      />
    </Form>
  );
}