import us from './flag/uk1.png';
import kazakhstan from './flag/kaz1.png';
import russia from './flag/rus1.png';

export default [
  {
    id: 'en',
    language: 'en',
    icon: us,
  },
  {
    id: 'kk',
    language: 'kk',
    icon: kazakhstan,
  },
  {
    id: 'ru',
    language: 'ru',
    icon: russia,
  }
];
