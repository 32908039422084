import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import LanguageProvider from '@deadline/common/LanguageSwitcher/context/language.provider';
import LanguageContext from '@deadline/common/LanguageSwitcher/context/language.context';
import LanguageSwitcher from '@deadline/common/LanguageSwitcher';
import languageConfig from '@deadline/common/LanguageSwitcher/config';
import ContactFormPortion from '@deadline/components/ContactForm/ContactForm';
import MainWrapper, {
  MainContentSection,
  ContactForm,
  FooterSection,
  LogoImageContainer,
  BottomShape,
} from '@deadline/common/ui/five.style';
import { SOCIAL_PROFILES } from '@deadline/common/data/social-share/five';

// Language translation files
import localEng from '@deadline/common/data/translation/nine/en.json';
import localKk from '@deadline/common/data/translation/nine/kk.json';
import localRu from '@deadline/common/data/translation/nine/ru.json';

import LogoImageEn from '@deadline/common/static/images/five/icon-soon-en.svg';
import LogoImageRu from '@deadline/common/static/images/five/icon-soon-ru.svg';
import LogoImageKk from '@deadline/common/static/images/five/icon-soon-kk.svg';

import { Container, SocialShare, SEO } from '../components';

const reactStringReplace = require('react-string-replace');

// Language translation Config
const messages = {
  en: localEng,
  kk: localKk,
  ru: localRu,
};

const logos = {
  en: LogoImageEn,
  kk: LogoImageKk,
  ru: LogoImageRu,
};

const IndexPage = () => {
  const Data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "five/bg.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  function linesToParagraphs(...nodes) {
    return nodes
        .map(node => typeof node === 'string' ? 
            node.split('\n').map(text => <div>{text}</div>) : node)
            .reduce((nodes, node) => nodes.concat(node), []);
  }

  function addSajda(...nodes) {
      return nodes.map(text =>  reactStringReplace(text, '%s', (match, i) => (<a href='https://sajda.app'>Sajda</a>))        )
        .reduce((nodes, node) => nodes.concat(node), [])
  }

  return (
    <ParallaxProvider>
      <LanguageProvider messages={messages}>
        <React.Fragment>
          <SEO title="title" description="instructions" keywords="keywords" />
          <BackgroundImage
            fluid={Data.background.childImageSharp.fluid}
            backgroundColor={`#fafbff`}
            Tag="div"
            className="gatsby-bg"
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
            }}
            fadeIn={true}
          >
            <div className="fullWrapper">
              <MainWrapper>
                
                  <div>
                  <LogoImageContainer>
                    <Link to={'/'}>
                       <LanguageContext.Consumer>
                          {(context) => (<img src={logos[context.state.lang]} alt="logo" />)}
                       </LanguageContext.Consumer>
                    </Link>
                  </LogoImageContainer>
                
                <Container className="mainContainer">
                  <MainContentSection>
                    <h2>
                      <FormattedMessage id="mainMessage">{linesToParagraphs}</FormattedMessage>
                    </h2>
                    <p class="abc" style={{color: '#444',}}>
                      <FormattedMessage id="instructions" />
                    </p>
                    <p style={{color: '#444',}}>
                      <FormattedMessage id="description" />
                    </p>

                    <ContactForm>
                      <ContactFormPortion />
                    </ContactForm>
                  </MainContentSection>
                  <FooterSection>
                    <SocialShare items={SOCIAL_PROFILES} />
                    <p>
                      <FormattedMessage id="copyrightText">{addSajda}</FormattedMessage>
                    </p>
                  </FooterSection>
                </Container>
                <div className="bottomAnimation">
                  <Parallax offsetYMax={50} offsetYMin={-40}>
                    <BottomShape>
                      
                    </BottomShape>
                  </Parallax>
                </div>
                  </div>
              </MainWrapper>
            </div>
          </BackgroundImage>
          <LanguageSwitcher languageConfig={languageConfig} />
        </React.Fragment>
      </LanguageProvider>
    </ParallaxProvider>
  );
};

export default IndexPage;
