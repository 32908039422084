import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';
import LanguageContext from '@deadline/common/LanguageSwitcher/context/language.context';
import config from '@deadline/common/data/config';

const SEO = ({ location = '', title, description, keywords, intl: { formatMessage } }) => {
  const {
    state: { lang },
  } = useContext(LanguageContext);
  const structuredDataOrganization = `{ 
				"@context": "http://schema.org",
				"@type": "Organization",
				"legalName": "${config.legalName}",
				"url": "${config.url}",
				"logo": "${config.logo}",
				"foundingDate": "${config.foundingDate}",
				"founders": [{
					"@type": "Person",
					"name": "${config.legalName}"
				}],
				"contactPoint": [{
					"@type": "ContactPoint",
					"email": "${config.contact.email}",
					"telephone": "${config.contact.phone}",
					"contactType": "customer service"
				}],
				"address": {
					"@type": "PostalAddress",
					"addressLocality": "${
            config.address.city
          }",
					"addressRegion": "${
            config.address.region
          }",
					"addressCountry": "${
            config.address.country
          }",
					"postalCode": "${config.address.zipCode}"
				},
				"sameAs": [
					"${config.socialLinks.facebook}",
					"${config.socialLinks.telegram}",
					"${config.socialLinks.instagram}",
          "${config.socialLinks.youtube}"
				]
			}`;

      
  const googleTagUrl = "https://www.googletagmanager.com/gtag/js?id=" + `${config.googleAnalyticsID}`
  const googleTagCode = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments)}gtag('js', new Date());gtag('config', '" + `${config.googleAnalyticsID}` + "')"

  return (
    <Helmet>
      <html lang={lang} dir={lang === 'ar' || lang === 'he' ? 'rtl' : 'ltr'} />
      
      <meta name="robots" content="index, follow" />
      <meta http-equiv="keywords" content={formatMessage({ id: keywords })} />
      <meta name="description" content={formatMessage({ id: description })} />
      <meta property="og:url" content={`${config.url}${location}`} />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={formatMessage({ id: title })}
      />
      <meta
        property="og:description"
        content={formatMessage({ id: description })}
      />
      <meta property="og:image" content={config.cover} />
      
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={config.socialLinks.twitter} />
      <meta
        name="twitter:title"
        content={formatMessage({ id: title })}
      />
      <meta
        name="twitter:description"
        content={formatMessage({ id: description })}
      />
      <meta name="twitter:image:src" content={config.cover} />
      <script type="application/ld+json">{structuredDataOrganization}</script>
      <link rel="publisher" href={config.socialLinks.google} />
      
      <title>{formatMessage({ id: title })}</title>
      
      <script async src={googleTagUrl}></script>
      <script>{googleTagCode}</script>
    </Helmet>
  );
};
export default injectIntl(SEO);
