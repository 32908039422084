// import img from '@deadline/common/static/images/bell.png';
// const img = '@deadline/common/static/images/bell.png';

module.exports = {
  title: 'Fajr',
  logo: 'https://fajr.app/icons/icon-512x512.png', // do not use queries ?v=
  url: 'https://fajr.app',
  favicon: '../common/static/favicon/fajr.png',
  cover: 'https://fajr.app/icons/icon-512x512.png?v=8bad923c51416f5e498694ce246aaf14', 
  legalName: 'Fajr.app',
  description: 'Learning salah is easy',
  socialLinks: {
    facebook: 'https://facebook.com/fajr.app1/',
    telegram: 'https://t.me/fajrapp/',
    instagram: 'https://instagram.com/fajr.app/',
    youtube: 'https://www.youtube.com/channel/UCqQA_PhV4MwXmv5GOvVmTIg'
  },
  googleAnalyticsID: "G-2MGTHX4SSM",
  themeColor: '#ffffff',
  backgroundColor: '#6496f6',
  address: {
    city: '',
    region: '',
    country: 'Kazakhstan',
    zipCode: '',
  },
  contact: {
    email: 'salam@fajr.app',
    phone: '+7-700-133-0000',
  },
  foundingDate: '2021'
};
