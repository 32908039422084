import { instagram } from 'react-icons-kit/fa/instagram'
import { telegram } from 'react-icons-kit/fa/telegram'
import { facebook } from 'react-icons-kit/fa/facebook';
import { youtube } from 'react-icons-kit/icomoon/youtube';
import dataConfig from '@deadline/common/data/config';


export const SOCIAL_PROFILES = [
  {
    id: 'facebook',
    icon: facebook,
    url: dataConfig.socialLinks.facebook,
  },
  {
    id: 'instagram',
    icon: instagram,
    url: dataConfig.socialLinks.instagram,
  },
  {
    id: 'telegram',
    icon: telegram,
    url: dataConfig.socialLinks.telegram,
  },
  {
    id: 'youtube',
    icon: youtube,
    url: dataConfig.socialLinks.youtube,
  }
];